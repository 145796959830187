import React from "react";
import { Layouts } from "../Layouts";
import { GovernorNavbar } from "../../components/GovernorNavbar";
import "./governor.css"

export const Governor = () => {
  return (
    <Layouts>
      <div className="container">
        <div className="post_article_extra">
          <GovernorNavbar />
        </div>
      </div>
    </Layouts>
  );
};
