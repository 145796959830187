import React, { useState } from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import { IoCallOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
import LogoHeader from "../../assets/img/gerb.png";

export const Header = () => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <header className="header">
            <div className="container">
                <div className="header-inner">
                    <div className="header_logo_wrapper">
                        {!isOpen && (
                            <div
                                className="overlay"
                                onClick={() => setIsOpen(true)}
                            ></div>
                        )}
                        <div className="hamburger_wrapper">
                            <RxHamburgerMenu
                                style={{
                                    cursor: "pointer",
                                    fontSize: "30px",
                                }}
                                onClick={() => {
                                    setIsOpen(!isOpen);
                                }}
                            />
                            <div
                                className="hamburger_menu"
                                style={{
                                    transform: `translate(${isOpen ? "-110" : "-10"
                                        }%)`,
                                }}
                            >
                                <div className="hamburger_menu_close_wrapper">
                                    <MdClose
                                        onClick={() => setIsOpen(true)}
                                        className="hamburger_menu_close"
                                    />
                                </div>
                                <h2 className="hamburger_title">
                                    ANDIJON VILOYATI HOKIMLIGI
                                    <hr
                                        style={{
                                            color: "white",
                                        }}
                                    />
                                </h2>
                                <ul className="hamburger_list">
                                    <li>
                                        <NavLink to="/yangliliklar">
                                            Yangiliklar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/hududlar-haqida">
                                            Hududlar haqida
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/sohalar">
                                            Sohalar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/qaror-va-farmonlar">
                                            Qaror va farmonlar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/public">
                                            Ochiq Ma'lumotlar
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/governor/biography">
                                        Viloyat Hokimligi
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <NavLink to="/" className="header-logo-link">
                            <img
                                className="header-logo-link-img"
                                src={LogoHeader}
                                alt="logo"
                            />
                            <div className="logo-box">
                                <p className="logo-p">ANDIJON VILOYATI</p>
                                <p className="logo-p-extra">HOKIMLIGI</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className="header_links">
                        <nav className="header-navigation">
                            <ul className="header-list">
                                <li className="header-item">
                                    <NavLink
                                        to="/yangliliklar"
                                        className="header-item-link"
                                    >
                                        Yangiliklar
                                    </NavLink>
                                </li>
                                <li className="header-item">
                                    <NavLink
                                        to="/hududlar-haqida"
                                        className="header-item-link"
                                    >
                                        Hududlar haqida
                                    </NavLink>
                                </li>
                                <li className="header-item">
                                    <NavLink
                                        to="/sohalar"
                                        className="header-item-link"
                                    >
                                        Sohalar
                                    </NavLink>
                                </li><li className="header-item">
                                    <NavLink
                                        to="/qaror-va-farmonlar"
                                        className="header-item-link"
                                    >
                                        Qaror va farmonlar
                                    </NavLink>
                                </li>
                                <li className="header-item">
                                    <NavLink
                                        to="/public"
                                        className="header-item-link"
                                    >
                                        Ochiq Ma'lumotlar
                                    </NavLink>
                                </li>
                                <li className="header-item">
                                    <NavLink
                                        className="header-item-link"
                                        to="/governor/biography"
                                    >
                                        Viloyat Hokimligi
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                        <NavLink className="header-btn" to="/contact">
                            Bog'lanish
                            <IoCallOutline />
                        </NavLink>
                    </div>
                </div>
            </div>
        </header>
    );
};
