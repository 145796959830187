import React from "react";

export const StatusPage = () => {
  return (
    <div >
    <article style={{marginTop: "30px"}}>
      <h2 className="article_bottom_title">Andijon Viloyati Hokimi maqomi</h2>
      <p style={{fontSize: "20px"}}>
        Ushbu sahifa hozirda ish jarayonida. tez orada ma'lumot chiqishi mumkin.
      </p>
    </article>
  </div>
  );
};
