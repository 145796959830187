import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Admin } from "../pages/Admin";
import Posts from "../pages/Posts/Posts";
import { AdminNews } from "../pages/AdminNews";
import { Contact } from "../pages/Contact";
import { AdminContact } from "../pages/AdminContact";
import { Governor } from "../pages/Governor";
import { Biography } from "../pages/Bography";
import { Rewards } from "../pages/Rewards";
import { StatusPage } from "../pages/StatusPage";
import { PostListLayout } from "../pages/PostList";
import { Qarorlar } from "../pages/Qarorlar";
import { AddPoliticsPage } from "../pages/AdminPolitics";
import { PoliticsPage } from "../pages/PoliticsPage";
import { Politics } from "../components/Politics";
import { News } from "../components/News";
import PoliticSingle from "../pages/PoliticSingle";
import { AdminRegion } from "../pages/AdminRegion";
import { AdminPublicInfo } from "../pages/AdminPublicInfo";
import HududlarHaqida from "../pages/HududlarHaqida";
import PublicPage from "../pages/PublicPage";
import { Login } from "../pages/Login";
import { NotFound } from "../pages/NotFound";
import PublicSinglePage from "../components/PublicPage";
import AboutTuman from "../pages/AboutTuman"
import {AdminQaror} from "../pages/AdminQaror"
import {AdminViloyat} from "../pages/AdminViloyat"

export const RouteWrapper = () => {
    const [admin, setAdmin] = useState(true);

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/is-admin", {
            headers: {
                auth: document.cookie.split("=")[1],
            },
        }).then((res) => {
            if (res.status === 200) {
                setAdmin(true);
            }
        });
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/politics" element={<PoliticsPage />} />
            <Route path="/login" element={<Login />} />
            {admin ? (
                <Route path="/lazy_programmer_admin_page" element={<Admin />} />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
            {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_news"
                    element={<AdminNews />}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
            {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_contact"
                    element={<AdminContact />}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}

            <Route path="/posts/:id" element={<Posts />} />
            <Route path="/hududlar-haqida" element={<HududlarHaqida />} >
                <Route path="umumiy-korsatkichlar" element={<AboutTuman />} />
                <Route path=":id" element={<News />} />
            </Route>
            <Route path="/politic/:id" element={<PoliticSingle />} />
            {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_contact"
                    element={<AdminContact />}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
            {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_politics"
                    element={<AddPoliticsPage />}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
            {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_region"
                    element={<AdminRegion />}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
            {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_publicinfo"
                    element={<AdminPublicInfo />}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
              {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_qaror"
                    element={<AdminQaror/>}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}
              {admin ? (
                <Route
                    path="/lazy_programmer_admin_page_andijan_region"
                    element={<AdminViloyat/>}
                />
            ) : (
                <Route path="/login" element={<Login />} />
            )}

            <Route path="/yangliliklar" element={<PostListLayout />} />
            <Route path="/qaror-va-farmonlar" element={<Qarorlar />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/governor" element={<Governor />}>
                <Route path="governor" element={<StatusPage />} />
                <Route path="biography" element={<Biography />} />
                <Route path="viloyat-hokimligi" element={<Rewards />} />
            </Route>
            <Route path="/sohalar" element={<PoliticsPage />}>
                <Route path=":id" element={<Politics />} />
            </Route>
            <Route path="/public" element={<PublicPage />} />
            <Route path="/public/:id" element={<PublicSinglePage />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
