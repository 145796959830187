import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Trash from "../../assets/img/recycle-bin.png";
import { Skeleton } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PoliticsList() {
  const [news, setNews] = React.useState([]);

  React.useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + "/siyosat")
      .then((res) => res.json())
      .then((data) => setNews(data))
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = (e) => {
    fetch(process.env.REACT_APP_BASE_URL + "/siyosat", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        auth: document.cookie.split("=")[1],
      },
      body: JSON.stringify({
        id: e,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.message === "deleted") {
          window.location.reload()
        }
        alert(data.message)})
      .catch((error) => console.log(error));
  };

  return (
    <div className="container" style={{ marginBottom: "150px" }}>
      <h2 className="add-slider-paragraph">Soha yangiliklarni boshqarish</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ background: "#0b4aa8" }}>
                Rasm
              </StyledTableCell>
              <StyledTableCell align="right" style={{ background: "#0b4aa8" }}>
                Sarlavha
              </StyledTableCell>
              <StyledTableCell align="right" style={{ background: "#0b4aa8" }}>
                Text
              </StyledTableCell>
              <StyledTableCell align="right" style={{ background: "#0b4aa8" }}>
                Kategory
              </StyledTableCell>
              <StyledTableCell align="right" style={{ background: "#0b4aa8" }}>
                O'chirish
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {news.data ? (
              news.data.map((element, idx) => (
                <StyledTableRow key={idx}>
                  <StyledTableCell component="th" scope="row">
                    <img
                      src={element?.img_url}
                      alt="img"
                      width={50}
                      height={50}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {element?.title}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {element?.description}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {element?.siyosat_category?.title}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <img
                      src={Trash}
                      alt="trash"
                      width={30}
                      height={30}
                      onClick={() => handleDelete(element?.id)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100px"
                    sx={{
                      borderRadius: "6px",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100px"
                    sx={{
                      borderRadius: "6px",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100px"
                    sx={{
                      borderRadius: "6px",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100px"
                    sx={{
                      borderRadius: "6px",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100px"
                    sx={{
                      borderRadius: "6px",
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
