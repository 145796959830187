import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import "./News.css";
import { Skeleton } from "@mui/material";
import { formatDate } from "../../lib/formatDate";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";
import {
    firstPageButton,
    lastPageButton,
    middlePageButton,
} from "../../lib/pagination";
import Button from "@mui/material/Button";

export const field = [
    {
        name: "Hammasi",
    },
    {
        name: "Ijtimoiy",
    },
    {
        name: "Iqtisodiy",
    },
    {
        name: "Xotin qizlar",
    },
    {
        name: "Qishloq xo'jaligi",
    },
    {
        name: "Investitsiya",
    },
];
export const decision = [
    {
        name: "Hammasi",
    },
    {
        name: "Prezident qaror va farmonlari",
    },
    {
        name: "Vazirlar mahkamasi",
    },
    {
        name: "Viloyat hokimligi",
    },
    {
        name: "Vazirlik idoralari",
    },
];
const News = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    // eslint-disable-next-line
    const [page_count, setPage_count] = useState(0);
    const { pathname, search } = useLocation();
    const query = search.slice(1).split("&").join("=").split("=");
    const params = useParams();
    const queryObj = {
        page: 1,
        decision: "Hammasi",
        field: "Hammasi",
    };
    const navigate = useNavigate();

    for (let i = 0; i < query.length; i += 2) {
        queryObj[query[i]] = query[i + 1];
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(
            process.env.REACT_APP_BASE_URL + "/post-by-category/" + params?.id
        )
            .then((res) => res.json())
            .then((data) => {
                setError(data?.message);
                setLoading(false);
                setEvents(data?.data);
            })
            .catch((err) => {
                setError(err?.message);
                setLoading(false);
                setEvents({});
                console.error(err);
            });
        // eslint-disable-next-line
    }, [+queryObj.page, pathname]);
    return (
        <div className="event_items_wrapper">
            {pathname === "/post/ef612827-f75c-4f36-9435-2362db113793" && (
                <div
                    style={{
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        flexWrap: "wrap",
                    }}
                >
                    {field.map((e) => {
                        return (
                            <Button
                                key={e.name}
                                sx={{ width: "max-width" }}
                                variant={
                                    e.name ===
                                    decodeURIComponent(queryObj.field)
                                        ? "contained"
                                        : "outlined"
                                }
                                onClick={() => {
                                    setLoading(false);
                                    navigate(pathname + "?field=" + e.name);
                                }}
                            >
                                {e.name}
                            </Button>
                        );
                    })}
                </div>
            )}
            {pathname === "/post/5156008d-041c-47fb-abf1-e05d41fd1035" && (
                <div
                    style={{
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        flexWrap: "wrap",
                    }}
                >
                    {decision.map((e) => {
                        return (
                            <Button
                                key={e.name}
                                sx={{ width: "max-width" }}
                                variant={
                                    e.name ===
                                    decodeURIComponent(queryObj.decision)
                                        ? "contained"
                                        : "outlined"
                                }
                                onClick={() => {
                                    setLoading(false);
                                    navigate(pathname + "?decision=" + e.name);
                                }}
                            >
                                {e.name}
                            </Button>
                        );
                    })}
                </div>
            )}
            {events[0] || loading ? (
                events?.map((e, i) => {
                    const date = new Date(e?.created_at);
                    return (
                        <div
                            className="event_item"
                            key={e.id}
                            style={{ animationDelay: `0.${i}s` }}
                        >
                            <Link
                                to={`/posts/${e.id}`}
                                className="event_item_img"
                            >
                                <LazyLoadImage
                                    effect="blur"
                                    src={e.img_url}
                                    alt=""
                                    width="100%"
                                    height="300"
                                    style={{
                                        position: "absolute",
                                        left: "0",
                                        top: "-10px",
                                        objectFit: "cover",
                                        zIndex: "1",
                                        filter: "blur(2px)",
                                    }}
                                />
                                <LazyLoadImage
                                    effect="blur"
                                    src={e.img_url}
                                    alt=""
                                    width="100%"
                                    height="200"
                                    style={{
                                        objectFit: "contain",
                                    }}
                                />
                            </Link>
                            <div className="event_text_wrapper">
                                <Link to={`/posts/${e.id}`}>{e.title}</Link>
                                <div className="event_date">
                                    <span>{`${formatDate(
                                        date?.getDate()
                                    )}-${formatDate(
                                        date?.getMonth() + 1
                                    )}-${date?.getFullYear()}`}</span>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : !error ? (
                <div className="event_items_wrapper">
                    <div
                        className="event_item"
                        style={{
                            display: "flex",
                            boxShadow: "0 0 20px #ccc",
                            padding: "0 20px",
                        }}
                    >
                        <Skeleton
                            animation="wave"
                            width="100%"
                            height="300px"
                            sx={{
                                maxWidth: "400px",
                            }}
                        ></Skeleton>
                        <div className="news_skalet">
                            <br />
                            <br />
                            <Skeleton
                                animation="wave"
                                component="h1"
                                width="100%"
                            ></Skeleton>
                            <div className="event_date">
                                <Skeleton
                                    width="300px"
                                    height="40px"
                                ></Skeleton>
                            </div>
                        </div>
                    </div>
                    <div
                        className="event_item"
                        style={{
                            display: "flex",
                            boxShadow: "0 0 20px #ccc",
                            padding: "0 20px",
                        }}
                    >
                        <Skeleton
                            animation="wave"
                            width="100%"
                            height="300px"
                            sx={{
                                maxWidth: "400px",
                            }}
                        ></Skeleton>
                        <div className="news_skalet">
                            <br />
                            <br />
                            <Skeleton
                                animation="wave"
                                component="h1"
                                width="100%"
                            ></Skeleton>
                            <div className="event_date">
                                <Skeleton
                                    width="300px"
                                    height="40px"
                                ></Skeleton>
                            </div>
                        </div>
                    </div>
                    <div
                        className="event_item"
                        style={{
                            display: "flex",
                            boxShadow: "0 0 20px #ccc",
                            padding: "0 20px",
                        }}
                    >
                        <Skeleton
                            animation="wave"
                            width="100%"
                            height="300px"
                            sx={{
                                maxWidth: "400px",
                            }}
                        ></Skeleton>
                        <div className="news_skalet">
                            <br />
                            <br />
                            <Skeleton
                                animation="wave"
                                component="h1"
                                width="100%"
                            ></Skeleton>
                            <div className="event_date">
                                <Skeleton
                                    width="300px"
                                    height="40px"
                                ></Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <h2>{error}</h2>
            )}
            {((events[0] && page_count > 0) || loading) && !error ? (
                <div className="pagination">
                    <button
                        onClick={() =>
                            navigate(pathname + "?page=" + (+queryObj.page - 1))
                        }
                        disabled={queryObj.page <= 1}
                    >
                        <MdOutlineNavigateBefore />
                        <p>Orqaga</p>
                    </button>
                    <div className="pagination_navigators">
                        <Button
                            onClick={() =>
                                // eslint-disable-next-line
                                queryObj.page !== 0 &&
                                navigate(pathname + "?page=" + 1)
                            }
                            // eslint-disable-next-line
                            className={queryObj.page == 1 ? "active" : ""}
                        >
                            {firstPageButton(+queryObj.page)}
                        </Button>
                        <button
                            className={
                                // eslint-disable-next-line
                                queryObj.page != events.count - 2 &&
                                // eslint-disable-next-line
                                queryObj.page != 1
                                    ? "active"
                                    : ""
                            }
                            onClick={() =>
                                +queryObj.page < 1 // eslint-disable-next-line
                                    ? queryObj.page == 2
                                        ? queryObj.page
                                        : +queryObj.page + 1
                                    : +queryObj.page - 1
                            }
                        >
                            {middlePageButton(+queryObj.page)}
                        </button>
                        {page_count < 1 && (
                            <button
                                className={
                                    // eslint-disable-next-line
                                    page_count - 1 == queryObj.page
                                        ? "active"
                                        : ""
                                }
                                onClick={() =>
                                    +queryObj.page > 1
                                        ? +queryObj.page + 2
                                        : +queryObj.page + 1
                                }
                            >
                                {lastPageButton(+queryObj.page)}
                            </button>
                        )}
                    </div>
                    <button
                        onClick={() =>
                            navigate(pathname + "?page=" + (+queryObj.page + 1))
                        }
                        // eslint-disable-next-line
                        disabled={+queryObj.page == page_count - 1}
                    >
                        <p>Oldinga</p>
                        <MdOutlineNavigateNext />
                    </button>
                </div>
            ) : null}
        </div>
    );
};
export { News };
