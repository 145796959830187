import { Header } from "./../../components/Header/index";
import { Footer } from "./../../components/Footer/index";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { formatDate } from "./../../lib/formatDate";

const PublicPage = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(process.env.REACT_APP_BASE_URL + "/public")
            .then((res) => res.json())
            .then((data) => {
                setError(data?.message);
                setLoading(false);
                setEvents(data?.data);
            })
            .catch((err) => {
                setError(err?.message);
                setLoading(false);
                setEvents({});
                console.error(err);
            });
    }, []);
    return (
        <>
            <Header />
            <div className="container">
                <div className="postList_wrapper">
                    <div className="event_items_wrapper">
                        {events[0] || loading ? (
                            events?.map((e, i) => {
                                const date = new Date(e?.created_at);
                                const text = e.title.split(" ").slice(0, 10);
                                return (
                                    <div
                                        className="event_item"
                                        key={e.id}
                                        style={{ animationDelay: `0.${i}s` }}
                                    >
                                        <Link
                                            to={`/public/${e.id}`}
                                            className="event_item_img"
                                        >
                                            <LazyLoadImage
                                                effect="blur"
                                                src={e.img_url}
                                                alt=""
                                                width="100%"
                                                height="300"
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    top: "-10px",
                                                    objectFit: "cover",
                                                    zIndex: "1",
                                                    filter: "blur(2px)",
                                                }}
                                            />
                                            <LazyLoadImage
                                                effect="blur"
                                                src={e.img_url}
                                                alt=""
                                                width="100%"
                                                height="200"
                                                style={{
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </Link>
                                        <div className="event_text_wrapper">
                                            <Link to={`/posts/${e.id}`}>
                                                {text.join(" ")}
                                                {text.length >= 10 && "..."}
                                            </Link>
                                            <div className="event_date">
                                                <span>{`${formatDate(
                                                    date?.getDate()
                                                )}-${formatDate(
                                                    date?.getMonth() + 1
                                                )}-${date?.getFullYear()}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : !error ? (
                            <div>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "350px",
                                        display: "flex",
                                        boxShadow: "0 0 20px #ccc",
                                        margin: "50px 0",
                                        gap: "40px",
                                    }}
                                >
                                    <Skeleton
                                        animation="wave"
                                        sx={{
                                            position: "relative",
                                            width: "400px",
                                            height: "340px",
                                            top: "-30px",
                                            left: "20px",
                                        }}
                                    ></Skeleton>
                                    <div>
                                        <br />
                                        <br />
                                        <Skeleton
                                            animation="wave"
                                            component="h1"
                                            width="100%"
                                        ></Skeleton>
                                        <div className="event_date">
                                            <Skeleton
                                                width="300px"
                                                height="40px"
                                            ></Skeleton>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        boxShadow: "0 0 20px #ccc",
                                        margin: "50px 0",
                                        gap: "40px",
                                    }}
                                >
                                    <Skeleton
                                        animation="wave"
                                        sx={{
                                            position: "relative",
                                            width: "400px",
                                            height: "340px",
                                            top: "-30px",
                                            left: "20px",
                                        }}
                                    ></Skeleton>
                                    <div>
                                        <br />
                                        <br />
                                        <Skeleton
                                            animation="wave"
                                            component="h1"
                                            width="100%"
                                        ></Skeleton>
                                        <div className="event_date">
                                            <Skeleton
                                                width="300px"
                                                height="40px"
                                            ></Skeleton>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        boxShadow: "0 0 20px #ccc",
                                        gap: "40px",
                                    }}
                                >
                                    <Skeleton
                                        animation="wave"
                                        sx={{
                                            position: "relative",
                                            width: "400px",
                                            height: "340px",
                                            top: "-30px",
                                            left: "20px",
                                        }}
                                    ></Skeleton>
                                    <div>
                                        <br />
                                        <br />
                                        <Skeleton
                                            animation="wave"
                                            component="h1"
                                            width="100%"
                                        ></Skeleton>
                                        <div className="event_date">
                                            <Skeleton
                                                width="300px"
                                                height="40px"
                                            ></Skeleton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <h2 style={{ textAlign: "center" }}>
                                Nimadir xato ketdi
                            </h2>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PublicPage;
