import React from "react";
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import { Footer } from "../../components/Footer";
import { AddPolitics } from "../../components/AddPolitics";
import PoliticsList from "../../components/PoliticsList";

export const AddPoliticsPage = () => {
  return (
    <>
      <AdminNavbar />
      <AddPolitics />
      <PoliticsList />
      <Footer />
    </>
  );
};
