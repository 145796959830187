import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./contact.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Phone from "../../assets/img/phone-icon.png";
import Email from "../../assets/img/email-icon.png";

export const AddContact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState();
  const [text, setText] = useState("");

  const handleData = (e) => {
    e.preventDefault();
    fetch(process.env.REACT_APP_BASE_URL + "/support", {
      method: "POST", 
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        text: text,
      }),
    })
      .then((res) => res.json())
      .then((data) => alert(data.message))
      .catch((error) => console.log(error));
    setTimeout(() => {
      // location.reload();
    }, 2000);
  };

  return (
    <section className="contact">
      <div className="contact-top">
        <div
          className="container"
          style={{
            textAlign: "center",
            position: "relative",
            zIndex: "1",
            background: "transparent",
          }}
        >
          <Link className="contact-top-btn" to={"/"}>
            BOSH SAHIFA
          </Link>
          <h2 className="contact-top-title">BOG'LANISH</h2>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "30px" }}>
        <div className="contact-inner">
          <div className="contact-middle">
            <div className="contact-left">
              <p className="contact-left-p">| BIZ BILAN BOG'LANISH</p>
              <h2 className="contact-left-title">
                Bizning hodimlarimiz bilan bog'laning
              </h2>
              <p className="contact-left-text">
                Bizning hodimlar bilan bog'lanish uchun telefon qiling yoki
                emailga yozing. Xabar qoldirishda xush momilalik bilan murojat
                qiling. Sizning murojatlaringiz xodimlar tomonidan ko'rib
                chiqiladi.
              </p>
              <div className="contact-phone">
                <img
                  src={Phone}
                  alt="phone"
                  className="contact-left-img"
                  width={52}
                  height={52}
                />
                <div className="contact-left-mini">
                  <a href="tel:+998912892121" className="contact-mini-link" id="tel">
                  +99891-289-21-21
                  </a>
                  <a href="/" className="contact-mini-p">
                    Telefon raqam
                  </a>
                </div>
              </div>
              <div className="contact-phone">
                <img
                  src={Email}
                  alt="phone"
                  className="contact-left-img"
                  width={52}
                  height={32}
                />
                <div className="contact-left-mini">
                  <a href="https://andmatbuot@gmail.com" className="contact-mini-link">
                  andmatbuot@gmail.com
                  </a>
                  <a href="/" className="contact-mini-p">
                    Email
                  </a>
                </div>
              </div>
            </div>
            <div className="contact-right">
              <form onSubmit={handleData}>
                <Box
                  sx={{
                    "& > :not(style)": {
                      m: 1,
                      width: "100%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div className="contact-input-wrapper">
                    <p className="contact-input-wrapper-p">
                      Ismingizni kiriting
                    </p>
                    <TextField
                      id="outlined-basic"
                      label="Ism"
                      variant="outlined"
                      placeholder="Ism..."
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="contact-input-wrapper">
                    <p className="contact-input-wrapper-p">
                      Telefon raqam kiriting
                    </p>
                    <TextField
                      id="outlined-basic"
                      label="Telefon raqam"
                      variant="outlined"
                      placeholder="Telefon raqam..."
                      type="number"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value - 0)}
                    />
                  </div>
                  <div className="contact-input-wrapper">
                    <p className="contact-input-wrapper-p">Xabarni kiriting</p>
                    <TextField
                      id="outlined-basic"
                      label="Xabar qoldirish"
                      variant="outlined"
                      placeholder="xabar qoldirish..."
                      type="text"
                      required
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </div>
                  <div className="contact-input-wrapper">
                    <Button
                      className="contact-input-wrapper-btn"
                      variant="contained"
                      type="submit"
                    >
                      Xabar Yuborish
                    </Button>
                  </div>
                </Box>
              </form>
            </div>
          </div>
        </div>
        <iframe
          className="contact-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4423.892704718114!2d72.34590735352354!3d40.781948513742606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38bceca3e0cb1b81%3A0x46636138f78dac73!2sAndijon%20viloyati%20hokimiyati!5e1!3m2!1sen!2s!4v1704541941898!5m2!1sen!2s"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps - Andijon viloyati hokimiyati"
        ></iframe>
      </div>
    </section>
  );
};
