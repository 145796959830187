import React from 'react'
import { AddContact } from '../../components/AddContact'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'

export const Contact = () => {
  return (
    <>
      <Header/>
      <AddContact/>
      <Footer/>
    </>
  )
}
