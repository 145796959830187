import React from 'react'

export const Rewards = () => {
  return (
    <div>
      <article style={{ marginTop: "30px" }}>
        <h2>Viloyat Hokimligi</h2>
        <p style={{ fontSize: "20px" }}>
          Ushbu sahifa hozirda ish jarayonida. Tez orada ma'lumot chiqishi mumkin.
        </p>
      </article>
    </div>
  )
}
