import React, { useState } from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/material/Button";
import "./slider.css";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const AddSlider = () => {
    const [img, setImg] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const handleImg = (event) => {
        setImg(event.target.files[0]);
    };

    const formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", "chatImages");

    const handleData = (e) => {
        e.preventDefault();

        fetch("https://api.cloudinary.com/v1_1/dev4pmh5c/image/upload", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                fetch(process.env.REACT_APP_BASE_URL + "/swiper-post", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        auth: document.cookie.split("=")[1],
                    },
                    body: JSON.stringify({
                        title,
                        description,
                        img_url: data.url,
                    }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        if(data.message === "ok"){
                            window.location.reload()
                        }
                        alert(data.message)})
                    .catch((error) => console.log(error));
            });
    };
    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });
    return (
        <section className="add-slider">
            <div className="container">
                <div className="add-slider-inner">
                    <h2 className="add-slider-paragraph">Slaydni qo'shish</h2>
                    <form className="add-slider-form" onSubmit={handleData}>
                        <div className="add-slider-box">
                            <label
                                htmlFor="title"
                                className="add-slider-box-label"
                            >
                                Sarlavha
                                <Input
                                    className="add-slider-box-input"
                                    placeholder="sarlavha"
                                    variant="outlined"
                                    color="neutral"
                                    type="text"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </label>
                            <label
                                htmlFor="Img"
                                className="add-slider-box-label"
                            >
                                kamida 1440 &times; 600 hajm bo'lsin
                                <Button
                                    style={{
                                        width: "200px",
                                        marginTop: "20px",
                                        background: "#0b4aa8",
                                    }}
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                    onChange={(e) => handleImg(e)}
                                >
                                    Upload file
                                    <VisuallyHiddenInput type="file" />
                                </Button>
                            </label>
                        </div>
                        <div className="add-slider-box">
                            <label
                                htmlFor="discription"
                                className="add-slider-box-label-textarea"
                            >
                                <textarea
                                    id="discription"
                                    cols="50"
                                    rows="4"
                                    placeholder="slide matni..."
                                    className="add-slider-box-textarea"
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                ></textarea>
                            </label>
                        </div>
                        <Button
                            style={{ background: "#0b4aa8" }}
                            variant="contained"
                            type="submit"
                        >
                            Qo'shish
                        </Button>
                    </form>
                </div>
            </div>
        </section>
    );
};
