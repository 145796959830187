import { Header } from "./../../components/Header/index";
import { Footer } from "./../../components/Footer/index";
import {
    Link,
    useLocation,
} from "react-router-dom";
import "../PostList/PostList.css";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { formatDate } from "../../lib/formatDate";
import { Skeleton } from "@mui/material";

const Qarorlar = () => {
    const [topics, setTopics] = useState([]);
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetch(process.env.REACT_APP_BASE_URL + "/post-by-category/5156008d-041c-47fb-abf1-e05d41fd1035")
            .then((res) => res.json())
            .then((data) => {
                setLoading(false)
                setTopics(data.data)
            })
            .catch((err) => {
                setLoading(false)
                setTopics([]);
                console.error(err);
            });
        // eslint-disable-next-line
    }, [pathname]);
    return (
        <>
            <Header />
            <div className="container">
                <div className="postList_wrapper">
                    {topics[0] || loading ? (
                        topics?.map((e, i) => {
                            const date = new Date(e?.created_at);
                            return (
                                <div
                                    className="event_item"
                                    key={e.id}
                                    style={{ animationDelay: `0.${i}s` }}
                                >
                                    <Link
                                        to={`/posts/${e.id}`}
                                        className="event_item_img"
                                    >
                                        <LazyLoadImage
                                            effect="blur"
                                            src={e.img_url}
                                            alt=""
                                            width="100%"
                                            height="300"
                                            style={{
                                                position: "absolute",
                                                left: "0",
                                                top: "-10px",
                                                objectFit: "cover",
                                                zIndex: "1",
                                                filter: "blur(2px)",
                                            }}
                                        />
                                        <LazyLoadImage
                                            effect="blur"
                                            src={e.img_url}
                                            alt=""
                                            width="100%"
                                            height="200"
                                            style={{
                                                objectFit: "contain",
                                            }}
                                        />
                                    </Link>
                                    <div className="event_text_wrapper">
                                        <Link to={`/posts/${e.id}`}>{e.title}</Link>
                                        <div className="event_date">
                                            <span>{`${formatDate(
                                                date?.getDate()
                                            )}-${formatDate(
                                                date?.getMonth() + 1
                                            )}-${date?.getFullYear()}`}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : <div className="event_items_wrapper">
                        <div
                            className="event_item"
                            style={{
                                display: "flex",
                                boxShadow: "0 0 20px #ccc",
                                padding: "0 20px",
                            }}
                        >
                            <Skeleton
                                animation="wave"
                                width="100%"
                                height="300px"
                                sx={{
                                    maxWidth: "400px",
                                }}
                            ></Skeleton>
                            <div className="news_skalet">
                                <br />
                                <br />
                                <Skeleton
                                    animation="wave"
                                    component="h1"
                                    width="100%"
                                ></Skeleton>
                                <div className="event_date">
                                    <Skeleton
                                        width="300px"
                                        height="40px"
                                    ></Skeleton>
                                </div>
                            </div>
                        </div>
                        <div
                            className="event_item"
                            style={{
                                display: "flex",
                                boxShadow: "0 0 20px #ccc",
                                padding: "0 20px",
                            }}
                        >
                            <Skeleton
                                animation="wave"
                                width="100%"
                                height="300px"
                                sx={{
                                    maxWidth: "400px",
                                }}
                            ></Skeleton>
                            <div className="news_skalet">
                                <br />
                                <br />
                                <Skeleton
                                    animation="wave"
                                    component="h1"
                                    width="100%"
                                ></Skeleton>
                                <div className="event_date">
                                    <Skeleton
                                        width="300px"
                                        height="40px"
                                    ></Skeleton>
                                </div>
                            </div>
                        </div>
                        <div
                            className="event_item"
                            style={{
                                display: "flex",
                                boxShadow: "0 0 20px #ccc",
                                padding: "0 20px",
                            }}
                        >
                            <Skeleton
                                animation="wave"
                                width="100%"
                                height="300px"
                                sx={{
                                    maxWidth: "400px",
                                }}
                            ></Skeleton>
                            <div className="news_skalet">
                                <br />
                                <br />
                                <Skeleton
                                    animation="wave"
                                    component="h1"
                                    width="100%"
                                ></Skeleton>
                                <div className="event_date">
                                    <Skeleton
                                        width="300px"
                                        height="40px"
                                    ></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <Footer />
        </>
    );
};

export { Qarorlar };
