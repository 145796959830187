import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation, useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";

const Politics = () => {
    const [events, setEvents] = useState([]);
    const { pathname } = useLocation();
    const params = useParams();

    useEffect(() => {
        fetch(
            process.env.REACT_APP_BASE_URL +
                "/siyosat-by-category/" +
                params?.id
        )
            .then((res) => res.json())
            .then((data) => {
                setEvents(data.data);
            })
            .catch((err) => {
                setEvents({});
                console.error(err);
            });
        // eslint-disable-next-line
    }, [pathname]);

    return (
        <div className="event_items_wrapper">
            {events[0] ? (
                events?.map((e) => {
                    return (
                        <div className="event_item" key={e.id}>
                            <Link
                                to={`/politic/${e.id}`}
                                className="event_item_img"
                            >
                                <div className="event_item_img">
                                    <LazyLoadImage
                                        effect="blur"
                                        src={e.img_url}
                                        alt=""
                                        width="100%"
                                        height="300"
                                        style={{
                                            position: "absolute",
                                            left: "0",
                                            top: "-10px",
                                            objectFit: "cover",
                                            zIndex: "1",
                                            filter: "blur(2px)",
                                        }}
                                    />
                                    <LazyLoadImage
                                        effect="blur"
                                        src={e.img_url}
                                        alt=""
                                        width="100%"
                                        height="200"
                                        style={{
                                            objectFit: "contain",
                                        }}
                                    />
                                </div>
                            </Link>
                            <div className="event_text_wrapper">
                                <p style={{ marginLeft: "30px" }}>{e.title}</p>
                                <div className="event_date">
                                    <span>{e.created_at}</span>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="event_items_wrapper">
                    <div
                        className="event_item"
                        style={{
                            display: "flex",
                            boxShadow: "0 0 20px #ccc",
                            padding: "20px",
                        }}
                    >
                        <Skeleton
                            aanimation="wave"
                            width="100%"
                            height="300px"
                            sx={{
                                maxWidth: "400px",
                            }}
                        ></Skeleton>
                        <div className="scalet_text_wrapper">
                            <Skeleton
                                animation="wave"
                                component="h1"
                                width="100%"
                            ></Skeleton>
                            <div className="event_date">
                                <Skeleton
                                    width="300px"
                                    height="40px"
                                ></Skeleton>
                            </div>
                        </div>
                    </div>
                    <div
                        className="event_item"
                        style={{
                            display: "flex",
                            boxShadow: "0 0 20px #ccc",
                            padding: "20px",
                        }}
                    >
                        <Skeleton
                            animation="wave"
                            width="100%"
                            height="300px"
                            sx={{
                                maxWidth: "400px",
                            }}
                        ></Skeleton>
                        <div className="scalet_text_wrapper">
                            <Skeleton
                                animation="wave"
                                component="h1"
                                width="100%"
                            ></Skeleton>
                            <div className="event_date">
                                <Skeleton
                                    width="300px"
                                    height="40px"
                                ></Skeleton>
                            </div>
                        </div>
                    </div>
                    <div
                        className="event_item"
                        style={{
                            display: "flex",
                            boxShadow: "0 0 20px #ccc",
                            padding: "20px",
                        }}
                    >
                        <Skeleton
                            animation="wave"
                            width="100%"
                            height="300px"
                            sx={{
                                maxWidth: "400px",
                            }}
                        ></Skeleton>
                        <div className="scalet_text_wrapper">
                            <Skeleton
                                animation="wave"
                                component="h1"
                                width="100%"
                            ></Skeleton>
                            <div className="event_date">
                                <Skeleton
                                    width="300px"
                                    height="40px"
                                ></Skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export { Politics };
