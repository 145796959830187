import "./index.css";
import { useState } from "react";
import { FaArrowUp } from "react-icons/fa6";

const BackToTop = () => {
    const scrollToTop = (e) => {
        window.scrollTo(0, 0);
    };
    const [scrollTopCheck, setScrollTopCheck] = useState(false);
    window.addEventListener("scroll", () => {
        setScrollTopCheck(window.scrollY > 200);
    });
    return (
        <div className="fixedItem">
            {scrollTopCheck && (
                <div onClick={(e) => scrollToTop(e)} className="back-to-top">
                    <FaArrowUp />
                </div>
            )}
        </div>
    );
};

export { BackToTop };
