import React from "react";
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import { Footer } from "../../components/Footer";
import { AddRegion } from "../../components/AddRegion";

export const AdminRegion = () => {
  return (
    <>
      <AdminNavbar />
      <AddRegion />
      <Footer />
    </>
  );
};
