import { Link } from "react-router-dom";
import { LiaArrowRightSolid } from "react-icons/lia";
import "./home.css";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Skeleton } from "@mui/material";
import { formatDate } from "../../lib/formatDate";
export const HomeNews = () => {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/tuman")
            .then((res) => res.json())
            .then((data) => {
                setPosts(data.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <section className="home_news">
            <div className="container">
                <div className="home_new_inner">
                    <h2 className="home_new_paragraph">SO'NGGI YANGILIKLAR</h2>
                    <ul className="home_news-list">
                        {posts[0]
                            ? posts.slice(0, 9).map((e) => {
                                  const date = new Date(e?.created_at);
                                  const dateFormat = `${formatDate(
                                      date?.getDate()
                                  )}-${formatDate(
                                      date?.getMonth() + 1
                                  )}-${date?.getFullYear()}`;
                                  return (
                                      <li
                                          key={e?.id}
                                          className="home_news_item"
                                      >
                                          <Link
                                              to={"posts/" + e?.id}
                                              className="home_new_item"
                                          >
                                              <LazyLoadImage
                                                  effect="blur"
                                                  src={e.img_url}
                                                  className="home_news-img"
                                              />
                                              <div className="home_news_box">
                                                  <h4 className="home_news_paragraph">
                                                      {e?.title.slice(0, 50)}
                                                  </h4>
                                                  <div className="home_news_bottom_box">
                                                      <p className="home_news_date">
                                                          {dateFormat}
                                                      </p>
                                                      <LiaArrowRightSolid />
                                                  </div>
                                              </div>
                                          </Link>
                                      </li>
                                  );
                              })
                            : new Array(6).fill("$").map((e, i) => {
                                  return (
                                      <div
                                          key={i}
                                          className="skeleton_loading_wrapper"
                                      >
                                          <Skeleton
                                              animation="wave"
                                              variant="rectangular"
                                              width={433}
                                              height={250}
                                          ></Skeleton>
                                          <div className="skeleton_loading_text">
                                              <Skeleton
                                                  animation="wave"
                                                  variant="text"
                                                  height={100}
                                              ></Skeleton>
                                              <Skeleton
                                                  animation="wave"
                                                  variant="text"
                                                  width={110}
                                                  height={45}
                                              ></Skeleton>
                                          </div>
                                      </div>
                                  );
                              })}
                    </ul>
                    <Link to="/yangliliklar" className="home_news_btn">
                        Barcha so'nggi yangiliklar
                    </Link>
                </div>
            </div>
        </section>
    );
};
