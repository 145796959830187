import React from "react";
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import { AddNews } from "../../components/AddNews";
import NewsList from "../../components/NewsList";
import { Footer } from "../../components/Footer";

export const AdminNews = () => {
  return (
    <>
      <AdminNavbar />
      <AddNews />
      <NewsList />
      <Footer/>
    </>
  );
};
