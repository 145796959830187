import "./notfound.css"

export const NotFound = () => {
  return (
    <div className="notfound">
      <div className="container">
        <div className="notfound-inner">
          <h1 className="notfound-title">404</h1>
          <p className="notfound-text">
            Sahifa topilmadi!
          </p>
        </div>
      </div>
    </div>
  )
}
