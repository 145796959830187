import { Header } from "./../../components/Header/index";
import { Footer } from "./../../components/Footer/index";
import {
    NavLink,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import "../PostList/PostList.css";
import { useEffect, useState } from "react";

const HududlarHaqida = () => {
    const [topics, setTopics] = useState([]);
    const { pathname } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    console.log(params?.id);
    useEffect(() => {
        if (pathname !== "/hududlar-haqida/umumiy-korsatkichlar") {
            fetch(process.env.REACT_APP_BASE_URL + "/post-category")
                .then((res) => res.json())
                .then((data) => {
                    if (!params?.id) {
                        navigate("/hududlar-haqida/" + data[0].id);
                    }
                    setTopics([...data, { id: "umumiy-korsatkichlar", title: "Umumiy ko'rsatkichlar" }]);
                })
                .catch((err) => {
                    setTopics([]);
                    console.error(err);
                });
        }
        // eslint-disable-next-line
    }, [pathname]);
    return (
        <>
            <Header />
            <div className="container">
                <div className="postList_wrapper">
                    <div className="post_wrapper">
                        {topics?.map((e) => {
                            if (
                                [
                                    "32da94bb-3e2e-4bf4-a3e2-07d7900b78b6", 'ef612827-f75c-4f36-9435-2362db113793', '5156008d-041c-47fb-abf1-e05d41fd1035',
                                    "9064783b-63e0-423f-9aed-fd908f8fac91"
                                ]
                                    .includes(e.id)
                            ) {
                                return ""
                            }
                            return (
                                <NavLink key={e.id} to={`/hududlar-haqida/${e.id}`}>
                                    {e.title}
                                </NavLink>
                            );
                        })}
                    </div>
                    <Outlet />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HududlarHaqida;