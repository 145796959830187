import { useEffect, useState } from "react";

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import "./hududlar.css";
export const data = [
    {
        maydoni: 100,
        name: "Andijon",
    },
    {
        maydoni: 200,
        name: "Asaka",
    },
    {
        maydoni: 400,
        name: "Jalaquduq",
    },
    {
        maydoni: 200,
        name: "Qoʻrgʻontepa",
    },
    {
        maydoni: 700,
        name: "Baliqchi",
    },
    {
        maydoni: 400,
        name: "Boʻston",
    },
    {
        maydoni: 900,
        name: "Buloqboshi",
    },
    {
        maydoni: 300,
        name: "Izboskan",
    },
    {
        maydoni: 200,
        name: "Marhamat",
    },
    {
        maydoni: 500,
        name: "Oltinkoʻl",
    },
    {
        maydoni: 600,
        name: "Paxtaobod",
    },
    {
        maydoni: 100,
        name: "Shahrixon",
    },
    {
        maydoni: 800,
        name: "Ulugʻnor",
    },
    {
        maydoni: 100,
        name: "Xoʻjaobod",
    },
];
const TypesOf = [
    {
        name: "Yer maydoni",
        dataKey: "maydon",
    },
    {
        name: "Aholi soni",
        dataKey: "numberOfPeople",
    },
    {
        name: "Xotin qizlar",
        dataKey: "numberOfFemales",
    },
    {
        name: "Erkaklar soni",
        dataKey: "numberOfMans",
    },
    {
        name: "Bolalar soni",
        dataKey: "numberOfBoys",
    },
    {
        name: "O'quvchilar soni",
        dataKey: "numberOfPupils",
    },
    {
        name: "Nuroniylar soni",
        dataKey: "numberOfRetirees",
    },
    {
        name: "Nogironlar soni",
        dataKey: "numberOfDisabledPeople",
    },
];
const AboutTuman = () => {
    const [events, setEvents] = useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/tuman-about")
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setEvents(data);
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <div>
            <div className="hududlar-haqida">
                {TypesOf.map((e, i) => {
                    return (
                        <ResponsiveContainer
                            key={i}
                            width="100%"
                            height={250}
                            style={{
                                margin: i !== 0 && "150px 0",
                            }}
                        >
                            <h3>{e.name}</h3>
                            <BarChart
                                width={1400}
                                height={250}
                                data={events}
                                margin={{
                                    top: 5,
                                    right: 50,
                                    left: 10,
                                    bottom: 5,
                                }}
                                barSize={10}
                            >
                                <XAxis
                                    dataKey="tuman"
                                    scale="point"
                                    padding={{ left: 10, right: 10 }}
                                />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar
                                    dataKey={e.dataKey}
                                    fill="#8884d8"
                                    name={e.name}
                                    background={{ fill: "#eee" }}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    );
                })}
                <br />
            </div>
        </div>
    );
};
export default AboutTuman;
