export const firstPageButton = (page) => {
  return page < 2 ? (page < 2 ? page : page - 1) : page - 1;
};
export const middlePageButton = (page) => {
  // eslint-disable-next-line
  return page < 3 ? (page == 1 ? page + 1 : page) : page + 1;
};

export const lastPageButton = (page) => {
  return page < 4
    ? // eslint-disable-next-line
      page == 1
      ? page + 2
      : // eslint-disable-next-line
      page == 3
      ? page + 2
      : page + 1
    : page + 3;
};
