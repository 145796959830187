import { RouteWrapper } from "./router";
import { BackToTop } from "./components/backtotop/BackToTop";

function App() {
  return (
    <>
      <RouteWrapper />
      <BackToTop />
    </>
  );
}

export default App;
