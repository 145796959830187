import React from "react";
import { Layouts } from "../Layouts";
import {
    NavLink,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";

export const PoliticsPage = () => {
    const [topics, setTopics] = useState([]);
    const { pathname } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/siyosat-category")
            .then((res) => res.json())
            .then((data) => {
                if (!params?.id) {
                    navigate("/sohalar/" + data[0].id);
                }
                setTopics(data);
            })
            .catch((err) => {
                setTopics([]);
                console.error(err);
            });
        // eslint-disable-next-line
    }, [pathname]);

    return (
        <Layouts>
            <div className="container">
                <div className="postList_wrapper">
                    <div className="post_wrapper">
                        {topics?.map((e) => {
                            return (
                                <NavLink key={e.id} to={`/sohalar/${e.id}`}>
                                    {e.title}
                                </NavLink>
                            );
                        })}
                    </div>
                    <Outlet />
                </div>
            </div>
        </Layouts>
    );
};
