import React from "react";
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import { Footer } from "../../components/Footer";
import { AddPublicInfo } from "../../components/AddpublicInfo";
import PublicIfoList from "../../components/PublicInfoList";

export const AdminPublicInfo = () => {
  return (
    <>
      <AdminNavbar />
      <AddPublicInfo/>
      <PublicIfoList/>
      <Footer />
    </>
  );
};