import React from "react";
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import { Footer } from "../../components/Footer";
import { useState, useEffect } from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Trash from "../../assets/img/recycle-bin.png";
import { Skeleton } from "@mui/material";

///////////////////////////////////// list
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const AdminQaror = () => {
  const [img, setImg] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleImg = (event) => {
    setImg(event.target.files[0]);
  };

  const formData = new FormData();
  formData.append("file", img);
  formData.append("upload_preset", "chatImages");

  const handleData = (e) => {
    e.preventDefault();

    fetch("https://api.cloudinary.com/v1_1/dev4pmh5c/image/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(process.env.REACT_APP_BASE_URL + "/tuman", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            auth: document.cookie.split("=")[1],
          },
          body: JSON.stringify({
            title,
            description,
            img_url: data.url,
            category_id: "5156008d-041c-47fb-abf1-e05d41fd1035",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.message === "ok") {
              window.location.reload();
            }
            alert(data.message);
          })
          .catch((error) => console.log(error));
      });
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        "/post-by-category/5156008d-041c-47fb-abf1-e05d41fd1035"
    )
      .then((res) => res.json())
      .then((data) => {
        setNews(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = (e) => {
    fetch(process.env.REACT_APP_BASE_URL + "/tuman", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        auth: document.cookie.split("=")[1],
      },
      body: JSON.stringify({
        id: e,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "deleted") {
          window.location.reload();
        }
        alert(data.message);
      })
      .catch((error) => console.log(error));
    setTimeout(() => {
      // location.reload();
    }, 2000);
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <AdminNavbar />
      <section className="add-slider">
        <div className="container">
          <div className="add-slider-inner">
            <h2 className="add-slider-paragraph">
              Qarorlarga yangilik qo'shish
            </h2>
            <form className="add-slider-form" onSubmit={handleData}>
              <div className="add-slider-box">
                <label htmlFor="title" className="add-slider-box-label">
                  Sarlavha
                  <Input
                    className="add-slider-box-input"
                    placeholder="sarlavha"
                    variant="outlined"
                    color="neutral"
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </label>

                <label htmlFor="Img" className="add-slider-box-label">
                  Kamida 1440 &times; 600 hajm bo'lsin
                  <Button
                    style={{
                      width: "200px",
                      marginTop: "20px",
                      background: "#0b4aa8",
                    }}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    onChange={(e) => handleImg(e)}
                  >
                    Upload file
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </label>
              </div>
              <div className="add-slider-box">
                <label
                  htmlFor="discription"
                  className="add-slider-box-label-textarea"
                >
                  <textarea
                    id="discription"
                    cols="50"
                    rows="4"
                    placeholder="Qaror matni..."
                    className="add-slider-box-textarea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </label>
              </div>
              <Button
                style={{ background: "#0b4aa8" }}
                variant="contained"
                type="submit"
              >
                Qo'shish
              </Button>
            </form>
          </div>
        </div>
      </section>
      <div className="container" style={{ marginBottom: "150px" }}>
        <h2 className="add-slider-paragraph">
          Qaror yangiliklarini boshqarish
        </h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  Rasm
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  Sarlavha
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  Text
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  O'chirish
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news?.length ? (
                news?.map((element, idx) => (
                  <StyledTableRow key={idx}>
                    <StyledTableCell component="th" scope="row">
                      <img
                        src={element?.img_url}
                        alt="img"
                        width={50}
                        height={50}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.description}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <img
                        src={Trash}
                        alt="trash"
                        width={30}
                        height={30}
                        onClick={() => handleDelete(element?.id)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Footer />
    </>
  );
};
