import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { BsCalendar2Date } from "react-icons/bs";
import { Skeleton } from "@mui/material";
import { Footer } from "../../components/Footer";
import { formatDate } from "../../lib/formatDate";
const PoliticSingle = () => {
    const { id } = useParams();
    const [post, setPost] = useState();
    const date = new Date(post?.created_at);
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/siyosat/" + id)
            .then((res) => res.json())
            .then((data) => {
                setPost(data.data);
            })
            .catch((err) => console.log(err))
    }, [id]);


    return (
        <div>
            <Header />
            <div className="container">
                {post ? (
                    <article className="post_article">
                        <h2>{post?.title.slice(0, 150)}</h2>
                        <hr />
                        <div className="article_time">
                            <BsCalendar2Date />
                            <p>{`${formatDate(date?.getDate())}-${formatDate(
                                date?.getMonth() + 1
                            )}-${date?.getFullYear()}`}</p>
                        </div>
                        <img src={post?.img_url} alt="" />
                        <p>{post?.description}</p>
                    </article>
                ) : (
                    <div className="post_article">
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height={50}
                            sx={{ margin: "0 auto", borderRadius: "4px" }}
                        ></Skeleton>
                        <hr />
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width={130}
                            height={30}
                            sx={{ borderRadius: "4px" }}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height={600}
                            sx={{ borderRadius: "6px" }}
                        ></Skeleton>
                        <div>
                            {new Array(10).fill("#").map((e, i) => {
                                return (
                                    <Skeleton
                                        key={i}
                                        animation="wave"
                                        variant="text"
                                        width="100%"
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default PoliticSingle;
