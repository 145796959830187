import { NavLink, Outlet } from "react-router-dom";
import "./governor.css";

export const GovernorNavbar = () => {
  return (
      <div className="governor_wrapper">
        <div className="governor_next_wrapper">
          <nav>
            <NavLink to={"biography"} className="governor-item-link">
             Viloyat Hokimi
            </NavLink>

            <NavLink to={"viloyat-hokimligi"} className="governor-item-link">
            Viloyat Hokimligi
            </NavLink>
          </nav>
        </div>
        <Outlet />
      </div>
  );
};
