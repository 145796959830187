import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Trash from "../../assets/img/recycle-bin.png";
import { Skeleton } from "@mui/material";
import "./contact.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ContactList() {
    const [swiper, setSwiper] = React.useState([]);

    React.useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/support", {
            headers: {
                auth: document.cookie.split("=")[1],
            },
        })
            .then((res) => res.json())
            .then(({ data }) => setSwiper(data))
            .catch((err) => console.log(err));
    }, []);

    const handleDelete = (e) => {
        fetch(process.env.REACT_APP_BASE_URL + "/support", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                auth: document.cookie.split("=")[1]
            },
            body: JSON.stringify({
                id: e,
            }),
        })
            .then((res) => res.json())
            .then((data) => alert(data.message))
            .catch((error) => console.log(error));
        setTimeout(() => {
            // location.reload();
        }, 2000);
    };

    return (
        <div className="container" style={{ marginBottom: "260px" }}>
            <h2 className="add-slider-paragraph">Murojatlarni boshqarish</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ background: "#0b4aa8" }}>
                                Xabar
                            </StyledTableCell>
                            <StyledTableCell
                                align="right"
                                style={{ background: "#0b4aa8" }}
                            >
                                Ism
                            </StyledTableCell>
                            <StyledTableCell
                                align="right"
                                style={{ background: "#0b4aa8" }}
                            >
                                Telefon raqam
                            </StyledTableCell>
                            <StyledTableCell
                                align="right"
                                style={{ background: "#0b4aa8" }}
                            >
                                O'chirish
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {swiper?.length ? (
                            swiper.map((element, idx) => (
                                <StyledTableRow key={idx}>
                                    <StyledTableCell component="th" scope="row">
                                        {element?.text}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {element?.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {element?.phone}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <img
                                            src={Trash}
                                            alt="trash"
                                            width={30}
                                            height={30}
                                            onClick={() =>
                                                handleDelete(element?.id)
                                            }
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100px"
                                            sx={{
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
