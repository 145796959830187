import React from "react";
import GovernorImg from "../../assets/img/hokim.jpg";
import "./bio.css";
import Telegram from "../../assets/img/telegram.png";
import { FaXTwitter } from "react-icons/fa6";
import Youtube from "../../assets/img/youtube.png";
import Insta from "../../assets/img/instagram.png";
import FaceBook from "../../assets/img/facebook.png";

export const Biography = () => {
  return (
    <div>
      <article>
        <h2 style={{ fontSize: "30px" }}>
          {" "}
          Shuhratbek Qoʻshaqbayevich Abduraxmanov
        </h2>
        <div className="biography-box">
          <img
            src={GovernorImg}
            alt="hokim"
            className="post_article_extra_img"
          />
          <p className="post_article_extra_p">
            Shuhratbek Qoʻshaqbayevich Abduraxmanov 1969 yil 25 dekabrda Andijon
            viloyatining Izboskan tumanida dehqonlar oilasida tugʻilgan. Millati
            – oʻzbek. Oliy maʼlumotli, 1992 yili Andijon paxtachilik institutini
            tugatgan. <br /> Olim-agronom mutaxassisligiga ega. Mehnat
            faoliyatini 1992 yilda Andijon viloyati Paxtaobod tumani “Madaniyat”
            agrofirmasi 5-boʻlimi agronom-entomologi vazifasidan boshlab,
            paxtachilik brigadasi boshligʻi, agronom, bosh agronom kabi
            vazifalarda faoliyat olib bordi. <br /> 1999 yili Paxtaobod tumani
            qishloq va suv xoʻjaligi boshqarmasi boshligʻining qishloq xoʻjaligi
            ishlari boʻyicha oʻrinbosari sifatida faoliyat koʻrsatdi.
            <br /> 2001 yilda Paxtaobod tumani hokimining birinchi oʻrinbosari,
            tuman qishloq va suv xoʻjaligi boʻlimi boshligʻi lavozimiga
            tayinlangan. 2001-2004 yillarda Andijon viloyati Paxtaobod tumani
            hokimining birinchi oʻrinbosari, tuman qishloq va suv xoʻjaligi
            boʻlimi boshligʻi boʻlgan.
          </p>
        </div>
        <p className="post_article_extra_p">
          Sh.Q.Abdurahmonov 2004 yilda Paxtaobod tumani hokimi lavozimiga
          tayinlangan. <br /> 2011 yilga qadar ushbu lavozimda faoliyat yuritib,
          2011 yilda Andijon viloyati hokimining oʻrinbosari, viloyat qishloq va
          suv xoʻjaligi boshqarmasi boshligʻi lavozimiga tayinlanib, 2013 yilga
          qadar ayni ushbu lavozimda faoliyat olib borgan. Sh.Q.Abdurahmonov
          2013 yildan boshlab, Andijon viloyati hokimi lavozimida faoliyat
          britib kelmoqda. <br /> Sh.Q.Abdurahmonov oilali, ikki qiz, bir oʻgʻli
          bor. Sh.Q.Abdurahmonov davlat va boshqaruv organlaridagi koʻp yillik
          samarali mehnati, yurtimiz ravnaqi, xalqimiz farovonligini
          yuksaltirish ishiga ulkan hissa qoʻshgani uchun “Shuhrat” medali va
          “Mehnat shuhrat” ordenlari bilan taqdirlangan. 2020 yidan boshlab Xalq
          deputatlari Andijon viloyati Kengashining raisi, Oliy majlisi Senati
          aʼzosi hisoblanadi.
        </p>
        <div className="social_media_inner social_media_inner_extra">
          <ul className="social_media_list">
            <li className="social_media_item">
              <a href="/" className="social_media_link">
                <img
                  src={Telegram}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Telegram
              </a>
            </li>
            <li className="social_media_item">
              <a href="/" className="social_media_link">
                <img
                  src={Insta}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Instagram
              </a>
            </li>
            <li className="social_media_item">
              <a href="/" className="social_media_link">
                <img
                  src={FaceBook}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Facebook
              </a>
            </li>
            <li className="social_media_item">
              <a href="/" className="social_media_link">
                <FaXTwitter size="30px" />
                Twitter
              </a>
            </li>
            <li className="social_media_item">
              <a href="/" className="social_media_link">
                <img
                  src={Youtube}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Youtube
              </a>
            </li>
          </ul>
          <p className="social_media_link_p">2024-01-07</p>
        </div>
      </article>
    </div>
  );
};
