import React from 'react'
import { Footer } from '../../components/Footer'
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import ContactList from '../../components/ContactList'

export const AdminContact = () => {
  return (
    <>
      <AdminNavbar/>
      <ContactList/>
      <Footer/>
    </>
  )
}
