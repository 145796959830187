import React, { useEffect, useState } from "react";
import Input from "@mui/joy/Input";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { data } from "../../pages/AboutTuman";
import { Button } from "@mui/joy";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Trash from "../../assets/img/recycle-bin.png";
import { Skeleton } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   [`&.${tableCellClasses.head}`]: {
  //     backgroundColor: theme.palette.common.black,
  //     color: theme.palette.common.white,
  //   },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const peopledata = [
  { title: "Yer maydoni", name: "maydon" },
  { title: "Aholi soni", name: "numberOfPeople" },
  { title: "Xotin qizlar", name: "numberOfFemales" },
  { title: "Erkaklar soni", name: "numberOfMans" },
  { title: "Yoshlar soni", name: "numberOfAdults" },
  { title: "Bolalar soni", name: "numberOfBoys" },
  { title: "O'quvchilar soni", name: "numberOfPupils" },
  { title: "Nuroniylar soni", name: "numberOfRetirees" },
  { title: "Nogironlar soni", name: "numberOfDisabledPeople" },
];
export const AddRegion = () => {
  const [loading, setLoading] = useState("");
  const [category1, setCategroy1] = useState("Andijon");
  const [people, setPeople] = useState([]);

  const [open1, setOpen1] = useState(false);
  const [values, setValues] = useState([]);

  const [pageCategory, setPageCategroy] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange1 = (event) => {
    setCategroy1(event.target.value);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleData = (e) => {
    setLoading("loading");
    e.preventDefault();
    const action = {};
    peopledata.forEach((el) => {
      action[el.name] = e.target[el.name].value;
    });

    fetch(process.env.REACT_APP_BASE_URL + "/tuman-about/" + values.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...action,
        tuman: category1,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setLoading("done");
          setTimeout(() => {
            setLoading("");
          }, 1000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading("");
        }, 1000);
        setLoading("done");
        console.log(error);
      });
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + "/tuman-about", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPeople(data);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    setValues(people.filter((e) => e.tuman === category1)[0]);
  }, [category1, people]);

  ////////////////////////////// hudud kategory

  const [img, setImg] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleImg = (event) => {
    setImg(event.target.files[0]);
  };

  const handlePageChange = (event) => {
    setPageCategroy(event.target.value);
  };

  const formData = new FormData();
  formData.append("file", img);
  formData.append("upload_preset", "chatImages");

  const handleRegion = (e) => {
    e.preventDefault();

    fetch("https://api.cloudinary.com/v1_1/dev4pmh5c/image/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(process.env.REACT_APP_BASE_URL + "/tuman", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            auth: document.cookie.split("=")[1],
          },
          body: JSON.stringify({
            title,
            description,
            img_url: data.url,
            category_id: pageCategory,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.message === "ok") {
              window.location.reload();
            }
            alert(data.message);
          })
          .catch((error) => console.log(error));
        setTimeout(() => {
          // location.reload();
        }, 2000);
      });
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  ///////////////////////////////////////// list

  const [news, setNews] = React.useState([]);

  React.useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + "/tuman")
      .then((res) => res.json())
      .then((data) => setNews(data.data))
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = (e) => {
    fetch(process.env.REACT_APP_BASE_URL + "/tuman", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        auth: document.cookie.split("=")[1],
      },
      body: JSON.stringify({
        id: e,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "deleted") {
          window.location.reload();
        }
        alert(data.message);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <section className="add-slider">
        <div className="container">
          <div className="add-slider-inner">
            <h2 className="add-slider-paragraph">
              Hududlarga yangilik qo'shish
            </h2>
            <form
              className="add-slider-form add-slider-box-extra"
              onSubmit={handleData}
            >
              <div
                className="add-slider-box add-slider-box-extra"
                style={{ flexWrap: "wrap" }}
              >
                <label
                  htmlFor="category1"
                  className="add-slider-box-label add-slider-box-label-extra"
                  style={{ gap: "0px" }}
                >
                  <Button
                    variant="plain"
                    className="add-slider-box-label-btn-extra"
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      color: "black",
                      fontSize: "16px",
                      padding: "0px",
                    }}
                    onClick={handleOpen1}
                  >
                    Tumanlar
                  </Button>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      id="demo-controlled-open-select"
                      open={open1}
                      onClose={handleClose1}
                      onOpen={handleOpen1}
                      value={category1}
                      onChange={handleChange1}
                      style={{ height: "34px" }}
                    >
                      {data &&
                        data.map((element, idx) => (
                          <MenuItem
                            onChange={() => setCategroy1(element.name)}
                            key={idx}
                            value={element.name}
                          >
                            {element.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </label>
                {peopledata.map((item, i) => (
                  <label
                    htmlFor={item.name}
                    className="add-slider-box-label"
                    style={{ marginLeft: "0px" }}
                    key={item.name}
                  >
                    {item.title}
                    <Input
                      className="add-slider-box-input"
                      variant="outlined"
                      color="neutral"
                      type="number"
                      id={item.name}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setValues((prev) => {
                            return {
                              ...prev,
                              [item.name]: e.target.value,
                            };
                          });
                        }
                      }}
                      name={item.tuman}
                      value={values && values[item?.name]}
                    />
                  </label>
                ))}
              </div>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading === "loading"}
                sx={{ minWidth: "100px", minHeight: "40px" }}
              >
                {loading === "done" ? (
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleSharpIcon />
                    Qo'shildi
                  </p>
                ) : null}
                {loading === "" ? "Qo'shish" : null}
              </LoadingButton>
            </form>
          </div>
        </div>
      </section>
      <section className="add-slider">
        <div className="container">
          <div className="add-slider-inner">
            <h2 className="add-slider-paragraph">
              Hududlarga yangilik qo'shish
            </h2>
            <form className="add-slider-form" onSubmit={handleRegion}>
              <div className="add-slider-box">
                <label htmlFor="title" className="add-slider-box-label">
                  Sarlavha
                  <Input
                    className="add-slider-box-input"
                    placeholder="sarlavha"
                    variant="outlined"
                    color="neutral"
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </label>
                <label htmlFor="category" className="add-slider-box-label">
                  <Button
                    className="add-slider-box-label-btn-extra"
                    sx={{
                      display: "none",
                      justifyContent: "start",
                      mt: 2,
                      color: "white",
                      fontSize: "16px",
                    }}
                    onClick={handleOpen}
                  >
                    Kategoriya
                  </Button>
                  <FormControl sx={{ m: 1, marginTop: "60px", minWidth: 120 }}>
                    <InputLabel id="demo-controlled-open-select-label">
                      K...
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={pageCategory}
                      label="Age"
                      onChange={handlePageChange}
                      style={{ height: "45px" }}
                    >
                      <MenuItem
                        value="60113242-b0aa-41c4-be13-8006fcbf0644"
                        onChange={(e) => setPageCategroy(e.target.value)}
                      >
                        Andijon haqida
                      </MenuItem>
                      <MenuItem
                        value="d1cb1f9f-efc3-4f52-b7a4-0625f14f0ce4"
                        onChange={(e) => setPageCategroy(e.target.value)}
                      >
                        Shahar va tumanlar
                      </MenuItem>
                    </Select>
                  </FormControl>
                </label>
              </div>
              <div className="add-slider-box">
                <label htmlFor="Img" className="add-slider-box-label">
                  Kamida 1440 &times; 600 hajm bo'lsin
                  <Button
                    style={{
                      width: "200px",
                      marginTop: "20px",
                      background: "#0b4aa8",
                      color: "white",
                    }}
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    onChange={(e) => handleImg(e)}
                  >
                    Upload file
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </label>
              </div>
              <div className="add-slider-box">
                <label
                  htmlFor="discription"
                  className="add-slider-box-label-textarea"
                >
                  <textarea
                    id="discription"
                    cols="50"
                    rows="4"
                    placeholder="Yangilik matni..."
                    className="add-slider-box-textarea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </label>
              </div>
              <Button
                style={{ background: "#0b4aa8", color: "white" }}
                variant="contained"
                type="submit"
              >
                Qo'shish
              </Button>
            </form>
          </div>
        </div>
      </section>
      <div className="container" style={{ marginBottom: "150px" }}>
        <h2 className="add-slider-paragraph">Hududlar yangiliklarni boshqarish</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ background: "#0b4aa8", color: "white" }}>
                  Rasm
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  Sarlavha
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  Text
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  Kategory
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  style={{ background: "#0b4aa8", color: "white" }}
                >
                  O'chirish
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news.data ? (
                news.data.map((element, idx) => element?.post_category?.title === "Andijon haqida" && (
                  <StyledTableRow key={idx}>
                    <StyledTableCell component="th" scope="row">
                      <img
                        src={element?.img_url}
                        alt="img"
                        width={50}
                        height={50}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.description}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.post_category?.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <img
                        src={Trash}
                        alt="trash"
                        width={30}
                        height={30}
                        onClick={() => handleDelete(element?.id)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              )}
               {news.data ? (
                news.data.map((element, idx) => element?.post_category?.title === "Shahar va tumanlar" && (
                  <StyledTableRow key={idx}>
                    <StyledTableCell component="th" scope="row">
                      <img
                        src={element?.img_url}
                        alt="img"
                        width={50}
                        height={50}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.description}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {element?.post_category?.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <img
                        src={Trash}
                        alt="trash"
                        width={30}
                        height={30}
                        onClick={() => handleDelete(element?.id)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100px"
                      sx={{
                        borderRadius: "6px",
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
