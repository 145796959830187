import React from "react";
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import { AddSlider } from "../../components/AddSlider";
import CustomizedTables from "../../components/SlideList";
import { Footer } from "../../components/Footer";

export const Admin = () => {
  return (
    <>
      <AdminNavbar />
      <AddSlider />
      <CustomizedTables/>
      <Footer/>
    </>
  );
};
