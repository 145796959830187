import "./admin.css";
import React from "react";
import AdminLogo from "../../assets/img/user-gear.png";
import { NavLink } from "react-router-dom";

export const AdminNavbar = () => {
  return (
    <header className="admin-header">
      <div className="container" style={{ background: "#0b4aa8" }}>
        <div className="admin-header-inner">
          <a href="/" className="header-logo-link">
            <img
              src={AdminLogo}
              alt="logo"
              className="header-logo"
              width={50}
              height={50}
            />
          </a>
          <nav className="admin-header-nav">
            <ul className="admin-header-list">
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page"
                  className="admin-header-item-link"
                >
                  Home
                </NavLink>
              </li>
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page_news"
                  className="admin-header-item-link"
                >
                  Yangiliklar
                </NavLink>
              </li>
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page_region"
                  className="admin-header-item-link"
                >
                  Hudular haqida
                </NavLink>
              </li>
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page_politics"
                  className="admin-header-item-link"
                >
                  Sohalar
                </NavLink>
              </li>
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page_publicinfo"
                  className="admin-header-item-link"
                >
                  Ochiq ma'lumotlar
                </NavLink>
              </li>
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page_qaror"
                  className="admin-header-item-link"
                >
                  Qaror va farmonlar
                </NavLink>
              </li>
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page_andijan_region"
                  className="admin-header-item-link"
                >
                  Viloyat hokimligi
                </NavLink>
              </li>
              <li className="admin-header-item">
                <NavLink
                  to="/lazy_programmer_admin_page_contact"
                  className="admin-header-item-link"
                >
                  Bog'lanish
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
