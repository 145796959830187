import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import FooterLogo from "../../assets/img/gerb.png";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container" style={{ background: "#0b4aa8" }}>
        <div className="footer-inner">
          <div className="footer-wrapper">
            <div className="footer_first">
              <Link to="/" className="footer-logo-link">
                <img
                  className="footer-logo-link-img"
                  src={FooterLogo}
                  alt="logo"
                  width={100}
                  height={85}
                />
                <div className="footer-logo-box">
                  <p className="footer-logo-p">ANDIJON VILOYATI</p>
                  <p className="footer-logo-p-extra">HOKIMLIGI</p>
                </div>
              </Link>
              <div className="footer-first-mini-extra">
                <p className="footer-text footer-text-extra">
                  Ma'lumotlardan foydalanilganda ushbu veb-sayt havolasi
                  ko'rsatilishi shart!
                </p>
                <p className="footer-text">
                  © {new Date().getFullYear()} Andijon viloyati hokimligi
                  matbuot xizmatining rasmiy veb-sayti
                </p>
                <p className="footer-text">Barcha huquqlar himoyalangan</p>
              </div>
            </div>
            <div className="footer-box">
              <div className="footer_second">
                <h4 className="footer-paragraph">Yangiliklar</h4>
                <ul className="footer-list">
                  <li className="footer-item">
                    <Link className="footer-item-link" to={"/yangliliklar"}>
                      Yangiliklar
                    </Link>
                  </li>
                </ul>
                <h4 className="footer-paragraph">Hududlar haqida</h4>
                <ul className="footer-list">
                  <li className="footer-item">
                    <Link
                      className="footer-item-link"
                      to={
                        "/hududlar-haqida/60113242-b0aa-41c4-be13-8006fcbf0644"
                      }
                    >
                      Andijon haqida
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      className="footer-item-link"
                      to={
                        "/hududlar-haqida/d1cb1f9f-efc3-4f52-b7a4-0625f14f0ce4"
                      }
                    >
                      Shahar va tumanlar
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      className="footer-item-link"
                      to={"/hududlar-haqida/umumiy-korsatkichlar"}
                    >
                      Umumiy ko'rsatkichlar
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer_second">
                <h4 className="footer-paragraph">Sohalar</h4>
                <ul className="footer-list">
                  <li className="footer-item">
                    <Link
                      to={"/sohalar/2aee409a-49d5-41a0-95a6-73e9db2f0336"}
                      className="footer-item-link"
                    >
                      Ijtimoiy
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      to={"/sohalar/2aa79ffc-46ec-40cf-a28e-83a094f7b111"}
                      className="footer-item-link"
                    >
                      Iqtisodiy
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      to={"/sohalar/a15af222-2a47-4f3a-b5be-88cdbd1f795e"}
                      className="footer-item-link"
                    >
                      Xotin-qizlar
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      to={"/sohalar/dfd242b5-16fb-4066-bd77-5ac731906a5c"}
                      className="footer-item-link"
                    >
                      Qishloq xo'jaligi
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      to={"/sohalar/6bc996e7-3dfd-419f-b4c4-5581ac9da1fc"}
                      className="footer-item-link"
                    >
                      Investitsiya
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer_second">
                <h4 className="footer-paragraph">Qaror va farmonlar</h4>
                <ul className="footer-list">
                  <li className="footer-item">
                    <Link
                      to={"/qaror-va-farmonlar"}
                      className="footer-item-link"
                    >
                      Qaror va farmonlar
                    </Link>
                  </li>
                </ul>
                <h4 className="footer-paragraph">Ochiq ma'lumotlar</h4>
                <ul className="footer-list">
                  <li className="footer-item">
                    <Link
                      to={"/public"}
                      className="footer-item-link"
                    >
                      Ochiq ma'lumotlar
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer_second">
                <h4 className="footer-paragraph">Viloyat hokimligi</h4>
                <ul className="footer-list">
                  <li className="footer-item">
                    <Link
                      to={"/governor/biography"}
                      className="footer-item-link"
                    >
                      Viloyat hokimi
                    </Link>
                  </li>
                  <li className="footer-item">
                    <Link
                      to={"/governor/viloyat-hokimligi"}
                      className="footer-item-link"
                    >
                      Viloyat hokimligi
                    </Link>
                  </li>
                </ul>
                <h4 className="footer-paragraph">Bog'lanish</h4>
                <ul className="footer-list">
                  <li className="footer-item">
                    <Link
                      to={"/contact"}
                      className="footer-item-link"
                    >
                      Bog'lanish
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
