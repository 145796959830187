import "./login.css";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const Login = () => {
    const navigate = useNavigate();
    const [login, setLogin] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleLogin = (e) => {
        e.preventDefault();

        fetch(process.env.REACT_APP_BASE_URL + "/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                auth: document.cookie.split("=")[1],
            },
            body: JSON.stringify({
                login,
                password,
            }),
        })
            .then(async (res) => {
                const response = await res.json();
                document.cookie = `authorization=${response.token}; expires=${response.expirationDate}`;
                if (res.status === 200) {
                    navigate("/lazy_programmer_admin_page");
                }
                if (res.status === 401) {
                    navigate("/login");
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <div className="login">
            <div className="container">
                <div className="login-inner">
                    <h2 className="login-title">Login</h2>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className="login-input-box">
                            <TextField
                                className="login-input"
                                id="outlined-helperText"
                                label="Login"
                                type="text"
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                            />
                        </div>
                        <div className="login-input-box">
                            <TextField
                                className="login-input"
                                id="outlined-helperText"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <Button
                            className="login-btn"
                            variant="contained"
                            onClick={handleLogin}
                        >
                            Login
                        </Button>
                    </Box>
                </div>
            </div>
        </div>
    );
};
