import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./slider.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
    Pagination,
    EffectFade,
    Navigation,
    Autoplay,
    Keyboard,
} from "swiper/modules";
import { Skeleton } from "@mui/material";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

export const Slider = () => {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const [swiper, setSwiper] = useState([]);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty("--progress", 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/swiper-post")
            .then((res) => res.json())
            .then(({ data }) => setSwiper(data))
            .catch((err) => console.log(err))
    }, []);
    return (
        <div>
            {swiper[0] ? (
                <Swiper
                    className="swiper"
                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                    modules={[
                        Autoplay,
                        EffectFade,
                        Pagination,
                        Navigation,
                        Keyboard,
                    ]}
                    keyboard={{
                        enabled: true,
                    }}
                    effect={"fade"}
                    loop={true}
                    navigation={true}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        dynamicBullets: true,
                        clickable: true,
                        type: "bullets",
                    }}
                >
                    {swiper?.map((e) => {
                        return (
                            <SwiperSlide key={e.id} className="swiper_wrapper">
                                <LazyLoadImage
                                    effect="blur"
                                    src={e.img_url}
                                    className="swiper_image"
                                    style={{
                                        objectFit: "cover",
                                        width: "100%",
                                    }}
                                />
                                <div className="hero_swiper_text">
                                    <Link to={"/news/" + e.id}>{e?.title}</Link>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                    <div className="autoplay-progress" slot="container-end">
                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                            <circle cx="24" cy="24" r="20"></circle>
                        </svg>
                        <span ref={progressContent}></span>
                    </div>
                </Swiper>
            ) : (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="800px"
                    sx={{
                        borderRadius: "6px",
                    }}
                />
            )}
        </div>
    );
};
