import React from "react";
import { AdminNavbar } from "../../components/AdminNavbar/idndex";
import { Footer } from "../../components/Footer";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Trash from "../../assets/img/recycle-bin.png";
import { Skeleton } from "@mui/material";

  ///////////////////////////////////// list
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

export const AdminViloyat = () => {
  const [news, setNews] = useState([]);
  const [description, setDescription] = useState('');

  const handleData = (e) => {
    e.preventDefault();
        fetch(process.env.REACT_APP_BASE_URL + "/viloyat-hokimligi", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            auth: document.cookie.split("=")[1],
          },
          body: JSON.stringify({
            text: description,
            id: news[0].id
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if(data.message === "updated"){
              window.location.reload()
            }
            alert(data.message)})
          .catch((error) => console.log(error));
  };


useEffect(() => {
  fetch(process.env.REACT_APP_BASE_URL + "/viloyat-hokimligi")
    .then((res) => res.json())
    .then((data) => setNews(data))
    .catch((err) => console.log(err));
}, []);

const handleDelete = (e) => {
  fetch(process.env.REACT_APP_BASE_URL + "/viloyat-hokimligi", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      auth: document.cookie.split("=")[1],
    },
    body: JSON.stringify({
      id: e,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      if(data.message === "deleted"){
        window.location.reload()
      }
      alert(data.message)})
    .catch((error) => console.log(error));
};

  return (
    <>
      <AdminNavbar />
      <section className="add-slider">
        <div className="container">
          <div className="add-slider-inner">
            <h2 className="add-slider-paragraph">
              Andijon viloyati yangiliklarini qo'shish
            </h2>
            <form className="add-slider-form" onSubmit={handleData}>
              <div className="add-slider-box">
                <label
                  htmlFor="discription"
                  className="add-slider-box-label-textarea"
                >
                  <textarea
                    id="discription"
                    cols="50"
                    rows="4"
                    placeholder="Yangilik matni..."
                    className="add-slider-box-textarea"
                    name={news[0]?.text}
                    defaultValue={description}
                    onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </label>
              </div>
              <Button
                style={{ background: "#0b4aa8" }}
                variant="contained"
                type="submit"
              >
                Qo'shish
              </Button>
            </form>
          </div>
        </div>
      </section>
      <div className="container" style={{ marginBottom: "150px" }}>
      <h2 className="add-slider-paragraph">Viloyat yangiliklarini boshqarish</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right" style={{ background: "#0b4aa8",color: "white" }}>
                Text
              </StyledTableCell>
              <StyledTableCell align="right" style={{ background: "#0b4aa8",color: "white" }}>
                O'chirish
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {news.length ? (
              news.map((element, idx) => (
                <StyledTableRow key={idx}>
                  <StyledTableCell align="right">
                    {element?.text}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <img
                      src={Trash}
                      alt="trash"
                      width={30}
                      height={30}
                      onClick={() => handleDelete(element?.id)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="right">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100px"
                    sx={{
                      borderRadius: "6px",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100px"
                    sx={{
                      borderRadius: "6px",
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
      <Footer />
    </>
  );
};

