import React from "react";
import Telegram from "../../assets/img/telegram.png";
import Youtube from "../../assets/img/youtube.png";
import Insta from "../../assets/img/instagram.png";
import FaceBook from "../../assets/img/facebook.png";
import "./media.css";

export const SocialMedia = () => {
  return (
    <section className="social_media">
      <div className="container">
        <div className="social_media_inner">
          <h1 className="social_media_paragraph">
            Andijon viloyati hokimligi matbuot xizmatining ijtimoiy tarmoqdagi rasmiy sahifalari:
          </h1>

          <ul className="social_media_list">
            <li className="social_media_item">
              <a href="https://t.me/andpress" className="social_media_link">
                <img
                  src={Telegram}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Telegram
              </a>
            </li>
            <li className="social_media_item">
              <a href="https://instagram.com/andpressa" className="social_media_link">
                <img
                  src={Insta}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Instagram
              </a>
            </li>
            <li className="social_media_item">
              <a href="https://fb.com/andpress" className="social_media_link">
                <img
                  src={FaceBook}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Facebook
              </a>
            </li>
            <li className="social_media_item">
              <a href="https://www.youtube.com/channel/UCU1FXhhr-kNZuqF5NldJnMw" className="social_media_link">
                <img
                  src={Youtube}
                  alt="telegram"
                  className="social_media_link_img"
                  width={30}
                  height={30}
                />
                Youtube
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
