import React, { useState } from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const AddPolitics = () => {
    const [img, setImg] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const [category, setCategroy] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const [news, setNews] = useState([]);

    React.useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + "/siyosat-category")
            .then((res) => res.json())
            .then((data) => setNews(data))
            .catch((err) => console.log(err));
    }, []);

    const handleChange = (event) => {
        setCategroy(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleImg = (event) => {
        setImg(event.target.files[0]);
    };

    const formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", "chatImages");

    const handleData = (e) => {
        e.preventDefault();

        fetch("https://api.cloudinary.com/v1_1/dev4pmh5c/image/upload", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                fetch(process.env.REACT_APP_BASE_URL + "/siyosat", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        auth: document.cookie.split("=")[1],
                    },
                    body: JSON.stringify({
                        title,
                        category_id: category,
                        description,
                        img_url: data.url,
                    }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        if(data.message === "ok"){
                            window.location.reload()
                        }
                        alert(data.message)})
                    .catch((error) => console.log(error));
                setTimeout(() => {
                    // location.reload();
                }, 2000);
            });
    };
    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });
    return (
        <section className="add-slider">
            <div className="container">
                <div className="add-slider-inner">
                    <h2 className="add-slider-paragraph">
                        Sohalarga yangilik qo'shish
                    </h2>
                    <form className="add-slider-form" onSubmit={handleData}>
                        <div className="add-slider-box">
                            <label
                                htmlFor="title"
                                className="add-slider-box-label"
                            >
                                Sarlavha
                                <Input
                                    className="add-slider-box-input"
                                    placeholder="sarlavha"
                                    variant="outlined"
                                    color="neutral"
                                    type="text"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </label>
                            <label
                                htmlFor="category"
                                className="add-slider-box-label"
                            >
                                <Button
                                    className="add-slider-box-label-btn-extra"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "start",
                                        mt: 2,
                                        color: "black",
                                        fontSize: "16px",
                                    }}
                                    onClick={handleOpen}
                                >
                                    Kategoriya
                                </Button>
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-controlled-open-select-label">
                                        K...
                                    </InputLabel>
                                    <Select
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        open={open}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={category}
                                        label="Age"
                                        onChange={handleChange}
                                        style={{ height: "45px" }}
                                    >
                                        {news.length &&
                                            news.map((element, idx) => (
                                                <MenuItem
                                                    onChange={() =>
                                                        setCategroy(element.id)
                                                    }
                                                    key={idx}
                                                    value={element.id}
                                                >
                                                    {element.title}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </label>
                            <label
                                htmlFor="Img"
                                className="add-slider-box-label"
                            >
                                Kamida 1440 &times; 600 hajm bo'lsin
                                <Button
                                    style={{
                                        width: "200px",
                                        marginTop: "20px",
                                        background: "#0b4aa8",
                                    }}
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                    onChange={(e) => handleImg(e)}
                                >
                                    Upload file
                                    <VisuallyHiddenInput type="file" />
                                </Button>
                            </label>
                        </div>
                        <div className="add-slider-box">
                            <label
                                htmlFor="discription"
                                className="add-slider-box-label-textarea"
                            >
                                <textarea
                                    id="discription"
                                    cols="50"
                                    rows="4"
                                    placeholder="Siyosiy yangilik matni..."
                                    className="add-slider-box-textarea"
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                ></textarea>
                            </label>
                        </div>
                        <Button
                            style={{ background: "#0b4aa8" }}
                            variant="contained"
                            type="submit"
                        >
                            Qo'shish
                        </Button>
                    </form>
                </div>
            </div>
        </section>
    );
};
