import React from "react";
import { Layouts } from "../Layouts";
import { Slider } from "../../components/Slider";
import { HomeNews } from "../../components/HomeNews";
import { SocialMedia } from "../../components/Media";

export const Home = () => {
  return (
    <Layouts>
      <main>
        <Slider />
        <HomeNews/>
        <SocialMedia/>
      </main>
    </Layouts>
  );
};
